import { useCallback } from 'react';

import { CompanyInfo, RegisterFormData } from '..';
import { useApi } from './useApi';

export function useAuthApi() {
  const { get, post } = useApi('auth');

  const initPasswordReset = useCallback(
    ({ email }: { email: string }) => post<{ access_token: string }>('forgot', { data: { email }, isPublic: true }),
    [post],
  );

  const login = useCallback(
    (data: { email: string; password: string }) => post<{ access_token: string }>('login', { data, isPublic: true }),
    [post],
  );

  const register = useCallback(
    (data: RegisterFormData & { companyUid?: string }) => post('register', { data, isPublic: true }),
    [post],
  );

  const resetPassword = useCallback(
    (data: { password: string; token: string }) => post('reset', { data, isPublic: true }),
    [post],
  );

  const siteDataClearance = useCallback(
    (data: { siteDataClearedAt?: Date }) =>
      post<{ isRequired: boolean }>('site-data-clearance', { data, isPublic: true }),
    [post],
  );

  const verify = useCallback(
    (companyUid: string) => get<CompanyInfo>('verify', { params: { companyUid }, isPublic: true }),
    [get],
  );

  return { initPasswordReset, login, register, resetPassword, siteDataClearance, verify };
}
